/* ========================================================================
* Add custom functions to the features setion below and follow format of existing functions.
* To fire functions on all pages or a specific page call your function in the specified area under efelleFunctions.
* ======================================================================== */
(function($) {
  var FEATURES = {

    //Sticky header
    stickyHeader: function() {
      $(window).scroll(function() {
        if ($(this).scrollTop() >= 50) {
          $('body').addClass('sticky');
        }else{
          $('body').removeClass('sticky');
        }
      });
    },

    //Masthead slider
    mastheadSlider: function() {
        if ($('.masthead').hasClass('slider')){
          var slides = $('div[id^="slide-"]').hide(),
          i = 0;

          (function cycle() {
            slides.eq(i).fadeIn(0).delay(5000).fadeOut(800, cycle).css('z-index', '1');
            i = ++i % slides.length;
            slides.eq(i).show().css('z-index', '0');
          })();
        }
    },

    //Mmenu function
    mobileMenu: function(yourMenu, yourButton) {

      $(yourMenu).mmenu({
        // options
        'offCanvas': {
            'position': 'right',
            'pageSelector': '#page-wrapper',
            'offCanvas': false,
         },
        'extensions': [
            'shadow-page',
         ]
      },{
         // configuration
         classNames: {
            fixedElements: {
               fixed: "banner"
            }
         }
       });

      var $icon = $(yourButton);
      var API = $(yourMenu).data( 'mmenu' );

      $icon.on( 'click', function() {
        API.open();
      });

      // API.bind( 'opened', function() {
      //   setTimeout(function() {
      //     $icon.addClass( 'menu-open' );
      //   }, 100);
      // });

      // API.bind( 'closed', function() {
      //   setTimeout(function() {
      //     $icon.removeClass( 'menu-open' );
      //   }, 100);
      // });

      // $('#nav-mobile .menu-item-has-children').click( function(){
      //   var subMenu = $(this).children('.mm-next');
      //   subMenu.click( function(e){
      //     e.stopPropagation();
      //   });
      //   subMenu.click();
      // });
    },
    //Center Bootstrap
    centerBootstrap: function(){
      var children = $('.events-posts').find('.events-wrap').size();
      var newClass = 'spacer' + children;
      console.log(newClass);
      if (children < 4) {
        $('.events-posts .events-wrap').first().addClass(newClass);
      }
    },
    //Footer Sign Up
    footerEmail: function(){
      $('.email-ref').click(function(){
        event.preventDefault();
        var userEmail = $('#email-var').val();
        var urlParam  = '/stay-updated/sign-up-for-our-newsletter?email=' + userEmail;
        window.location = urlParam;
      });
      $('#email-var').keydown(function(event){
        if(event.keyCode == 13) {
          console.log('test');
          event.preventDefault();
          $('.email-ref').click();
          return false;
        }
      });
    },
    addEmail: function(){
      function getURLParameter(url, name) {
        return (RegExp(name + '=' + '(.+?)(&|$)').exec(url)||[,null])[1];
      }
      var href      = window.location.href;
      console.log(href);
      var userEmail = getURLParameter(href, 'email');
      console.log(userEmail);
      if (userEmail !== '' || userEmail !== null){
        $('#recieved-email').val(userEmail);
      }
    },
    //Posts Filter

    //AJAX Call
    filterPosts: function(){
      var ajax_url = post_filter.ajax_url;
      function getPostsCat(pageNum, offset, category){
        $.ajax({
          type: 'GET',
          url: ajax_url,
          data: {
            action: 'post_filter', 
            category: category,
            page_number: pageNum,
            offset: offset,
          },
          beforeSend: function (){
            //FadeOut
            var children = $('#category-post-content').children();
            children.fadeOut('slow');
          },
          success: function(data){
            //Fade in new content
            $('#category-post-content').fadeIn('slow');
            $('#category-post-content').html(data);
          },
          error: function(){
            console.log('no posts found');
          }
        });
      }

      //Loads all posts with a category arg of NULL
      $( document ).ready(function() {
        var pageNum = 1;
        var offset  = 0;
        getPostsCat(pageNum, offset);
      });

      //Filter posts by tab clicked
      $('.fltr-btn').click(function(){
        var category  = $(this).data('filter');
        var pageNum   = 1;
        var offset    = 0;
        $('.current').removeClass();
        $(this).addClass('current');
        if (category === 'latest'){
          getPostsCat(pageNum, offset);
        } else {
          getPostsCat(pageNum, offset, category);
        }
      });
      
      //Pagination functions

      //search href and return value of name key
      function getURLParameter(url, name) {
        return (RegExp(name + '=' + '(.+?)(&|$)').exec(url)||[,null])[1];
      }

      //Get page href and call AJAX fucntion
      $('.content').click(function(event){
        var target = $(event.target);
        if(target.hasClass('page-numbers')){
          event.preventDefault();
          var href          = target.attr('href');
          var pageNumString = getURLParameter(href, 'paged');
          var pageNum       = parseInt(pageNumString);
          var offset        = (pageNum * 9) - 9; //Calculate the number of posts to offest.
          var category      = $('.current').data('filter');
          $("html, body").animate({ scrollTop: 0 }, 600);
          if (category === 'latest'){
            getPostsCat(pageNum, offset);
          } else {
            getPostsCat(pageNum, offset, category);
          }
        }
      });
    },

    //Map functions

    //Single project locations
    projectLocations: function(){
      google.maps.visualRefresh = true;
      var lat = $('#map-wrapper').data('lat');
      var lon = $('#map-wrapper').data('lon');

      $(function initMap() {
        var mapDiv    = document.getElementById('map-wrapper');
        var location  = {lat: lat, lng: lon};
        var map       = new google.maps.Map(mapDiv, {
          center: location,
          zoom: 12,
          scrollwheel: false,
        });
        var marker = new google.maps.Marker({
          position: location,
          map: map
        });
      });
    },

    countyMapFilter: function(){
      google.maps.visualRefresh = true;
      var mapDiv    = document.getElementById('mapCanvas');
      var center    = new google.maps.LatLng(47.4, -120.8);
      var viewport  = $(window).width();
      var drag;
      if(viewport < 769){
          drag = true;
      }else{
        drag = false;
      }
      var map = new google.maps.Map(mapDiv, {
        center: center,
        zoom: 7,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        backgroundColor: '#363b3d',
        disableDefaultUI: true,
        scaleControl: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        draggable: drag,
      });
      //The variables below set up markers for each county
      var image = '/wp-content/themes/efelle-wrc/assets/images/fff.png';
      var Adams = new google.maps.Marker({
        position: new google.maps.LatLng(47.004840, -118.533308),
        map: map,
        
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Adams',
          align: 'bottom'
        }
      });
      var Asotin = new google.maps.Marker({
        position: new google.maps.LatLng(46.181861, -117.227781),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Asotin',
        }
      });
      var Benton = new google.maps.Marker({
        position: new google.maps.LatLng(46.228072, -119.516864),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Benton',
        }
      });
      var Chelan = new google.maps.Marker({
        position: new google.maps.LatLng(47.859891, -120.618543),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Chelan',
        }
      });
      var Clallam = new google.maps.Marker({
        position: new google.maps.LatLng(48.113009, -123.930611),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Clallam',
        }
      });
      var Clark = new google.maps.Marker({
        position: new google.maps.LatLng(45.771674, -122.485903),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Clark',
        }
      });
      var Columbia = new google.maps.Marker({
        position: new google.maps.LatLng(46.292850, -117.911634),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Columbia',
        }
      });
      var Cowlitz = new google.maps.Marker({
        position: new google.maps.LatLng(46.185923, -122.658682),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Cowlitz',
        }
      });
      var Douglas = new google.maps.Marker({
        position: new google.maps.LatLng(47.735866, -119.695880),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Douglas',
        }
      });
      var Ferry = new google.maps.Marker({
        position: new google.maps.LatLng(48.437246, -118.517074),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Ferry',
        }
      });
      var Franklin = new google.maps.Marker({
        position: new google.maps.LatLng(46.534580, -118.906944),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Franklin',
        }
      });
      var Garfield = new google.maps.Marker({
        position: new google.maps.LatLng(46.429474, -117.536714),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Garfield',
        }
      });
      var Grant = new google.maps.Marker({
        position: new google.maps.LatLng(47.213633, -119.467788),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Grant',
        }
      });
      var Grays = new google.maps.Marker({
        position: new google.maps.LatLng(47.142786, -123.827043),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Grays Harbor',
        }
      });
      var Island = new google.maps.Marker({
        position: new google.maps.LatLng(48.158436, -122.670503),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Island',
        }
      });
      var Jefferson = new google.maps.Marker({
        position: new google.maps.LatLng(47.802641, -123.521810),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Jefferson',
        }
      });
      var King = new google.maps.Marker({
        position: new google.maps.LatLng(47.493554, -121.832375),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'King',
        }
      });
      var Kitsap = new google.maps.Marker({
        position: new google.maps.LatLng(47.639687, -122.649636),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Kitsap',
        }
      });
      var Kittitas = new google.maps.Marker({
        position: new google.maps.LatLng(47.124444, -120.676714),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Kittitas',
        }
      });
      var Klickitat = new google.maps.Marker({
        position: new google.maps.LatLng(45.869509, -120.780117),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Klickitat',
        }
      });
      var Lewis = new google.maps.Marker({
        position: new google.maps.LatLng(46.580071, -122.377444),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Lewis',
        }
      });
      var Lincoln = new google.maps.Marker({
        position: new google.maps.LatLng(47.582718, -118.417667),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Lincoln',
        }
      });
      var Mason = new google.maps.Marker({
        position: new google.maps.LatLng(47.354126, -123.173850),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Mason',
        }
      });
      var Okanogan = new google.maps.Marker({
        position: new google.maps.LatLng(48.550971, -119.691035),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Okanogan',
        }
      });
      var Pacific = new google.maps.Marker({
        position: new google.maps.LatLng(46.556587, -123.782419),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Pacific',
        }
      });
      var Pend = new google.maps.Marker({
        position: new google.maps.LatLng(48.543877, -117.4),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Pend Oreille',
        }
      });
      var Pierce = new google.maps.Marker({
        position: new google.maps.LatLng(47.040716, -122.144709),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Pierce',
        }
      });
      var San = new google.maps.Marker({
        position: new google.maps.LatLng(48.508861, -122.88),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'San Juan',
        }
      });
      var Skagit = new google.maps.Marker({
        position: new google.maps.LatLng(48.493066, -121.816278),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Skagit',
        }
      });
      var Skamania = new google.maps.Marker({
        position: new google.maps.LatLng(46.024782, -121.953227),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Skamania',
        }
      });
      var Snohomish = new google.maps.Marker({
        position: new google.maps.LatLng(48.054913, -121.766412),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Snohomish',
        }
      });
      var Spokane = new google.maps.Marker({
        position: new google.maps.LatLng(47.620379, -117.404392),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Spokane',
        }
      });
      var Stevens = new google.maps.Marker({
        position: new google.maps.LatLng(48.390648, -117.854897),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Stevens',
        }
      });
      var Thurston = new google.maps.Marker({
        position: new google.maps.LatLng(46.932598, -122.829441),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Thurston',
        }
      });
      var Wahkiakum = new google.maps.Marker({
        position: new google.maps.LatLng(46.294638, -123.424458),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Wahkiakum',
        }
      });
      var Walla = new google.maps.Marker({
        position: new google.maps.LatLng(46.154606, -118.580374),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Walla Walla',
        }
      });
      var Whatcom = new google.maps.Marker({
        position: new google.maps.LatLng(48.842653, -121.836432),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Whatcom',
        }
      });
      var Whitman = new google.maps.Marker({
        position: new google.maps.LatLng(46.903322, -117.522962),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Whitman',
        }
      });
      var Yakima = new google.maps.Marker({
        position: new google.maps.LatLng(46.456558, -120.740145),
        map: map,
        icon: image,
        label: {
          color: 'white',
          fontWeight: 'bold',
          text: 'Yakima',
        }
      });
      //Add fusiontable layer to map
      layer = new google.maps.FusionTablesLayer({
        map: map,
        heatmap: { enabled: false },
        query: {
          from: "1I2FseSaRnk4pMRmRgk7OlljQGEHRkdwpA5fNsKTR",
        },
        options: {
          styleId: 2,
          templateId: 2,
        }
      });
      google.maps.event.addDomListener(window, 'resize', function() {
        map.setCenter(center);
      });
    },

    //AJAX Call
    filterCounty: function(){
      var ajax_url = map_filter.ajax_url;

      function getCounty(countyProjects){
        $.ajax({
          type: 'GET',
          url: ajax_url,
          data: {
            action: 'map_filter', 
            countyProjects: countyProjects
          },
          beforeSend: function (){
            //FadeOut
          },
          success: function(data){
            //Fade in new content
            $('.googft-info-window').append(data);
            $('.googft-info-window').fadeIn('slow');
          },
          error: function(){
            console.log('no posts found');
          }
        });
      }
      $('#mapCanvas').click(function(){
        setTimeout(function(){
          var county  = $('.county-category').text();
          var popUp   = $('.googft-info-window');
          if ( popUp.hasClass('active')) {

          }else{
            popUp.addClass('active');
            getCounty(county);
          }
        },500);
      });
    },
    //Project Image Gallery
    projectGallery: function(){
      $('.slider-mast').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.slider-nav',
        adaptiveHeight: true,
      });
      $('.slider-nav').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: '.slider-mast',
        centerMode: true,
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 544,
            settings: {
            slidesToShow: 2
            }
          }
        ]
      });
    },
    //Footer Sponsor Reel
    sponsorReel: function(){
      $('.sponsor-images').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
      });
    },
    //Accrodian open on FAQ page
    accrodianOpen: function(){
      $('.su-spoiler:first').removeClass('su-spoiler-closed');
    },
    //Project Archive Filter
    projectArchiveFilter: function(){
      var grid = $('.grid').isotope({
        itemSelector: '.grid-item', // use a separate class for itemSelector, other than .col-
        percentPosition: true,
      });
      $('.taxonomies').change(function(){
        var catFilter = '.' + $(this).children('option:selected').data('term');
        console.log(catFilter);
        grid.isotope({ filter: catFilter});
      });
    },
  };



/* ========================================================================
 Add from features above to the specific page you wish them to be fired on
* ======================================================================== */


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var efelleFunctions = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        FEATURES.mobileMenu('#nav-mobile', '#hamburger');
        FEATURES.stickyHeader();
        FEATURES.sponsorReel();
        FEATURES.footerEmail();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        FEATURES.centerBootstrap();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    // Blog templatesingle-projects
    'page_template_template_blog': {
      init: function() {
        // JavaScript to be fired on the template-blog pages
        FEATURES.filterPosts();
      }
    },
    // Projects template
    'single_projects': {
      init: function() {
        // JavaScript to be fired on single-projects template pages
        FEATURES.projectGallery();
        FEATURES.projectLocations();
      }
    },
    'page_template_template_project_filter_page': {
      init: function() {
        FEATURES.countyMapFilter();
        FEATURES.filterCounty();
      }
    },
    'faq': {
      init: function() {
        FEATURES.accrodianOpen();
      }
    },
    'archive':{
      init: function(){
        FEATURES.projectArchiveFilter();
      }
    },
    'sign_up_for_our_newsletter': {
      init: function(){
        FEATURES.addEmail();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = efelleFunctions;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
